<template>
	<div>
		<a-spin :tip="spinnerLoaderLabel" size="large" :spinning="spinnerLoader">
			<div class="card">
				<div class="card-body">
					<div class="row">
						<div class="col-md-6">
							<h5 class="mb-4">
								<strong>Cuentas por Pagar</strong>
							</h5>
						</div>
						<div class="col-md-6 text-right">
							<a-button class="btn btn-success ml5" icon="reload" @click="initModule" />
						</div>
						<div class="col-md-12">
							<hr class="hrText" data-content="♦" />
						</div>
					</div>
					<FullCalendar :options="calendarOptions" />
				</div>
			</div>
		</a-spin>
	</div>
</template>
<script>
import '@fullcalendar/core/vdom'
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import esLocale from '@fullcalendar/core/locales/es'
import interactionPlugin from '@fullcalendar/interaction'

export default {
	components: {
		FullCalendar,
	},
	computed: {
		spinnerLoaderLabel() {
			return this.$store.state.debtsToPay.spinnerLoaderLabel
		},
		spinnerLoader() {
			return this.$store.state.debtsToPay.spinnerLoader
		},
	},
	data() {
		return {
			calendarOptions: {
				plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin],
				initialView: 'timeGridDay',
				locale: esLocale,
				businessHours: {
					daysOfWeek: [1, 2, 3, 4, 5, 6],
				},
				headerToolbar: {
					start: '',
					center: '',
					end: 'timeGridDay dayGridWeek dayGridMonth listWeek',
				},
				events: [
					{
						title: 'Meeting',
						start: '2021-10-09T10:30:00',
						end: '2021-10-09T17:30:00',
						extendedProps: {
							status: 'done',
						},
					},
					{
						title: 'Birthday Party',
						start: '2021-10-09T10:00:00',
						backgroundColor: 'green',
						borderColor: 'green',
					},
				],
			},
		}
	},
	methods: {
		initModule() {},
	},
}
</script>
